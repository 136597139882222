<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BackupHeader from "./backup-header.vue";
  import {
    backupDbList,
    backupDb,
    backupDbListExport
  } from "@/api/log/log.js";

  /**
   * 系统设置--数据库备份
   */
  export default {
    components: {
      Layout,
      BackupHeader,



    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "系统管理",
            active: true
          }
        ],
        query:{
          keyword: "",
          database:"",
          pageNum: 1,
          pageSize: 20
        },
        masterFileName: '',
        slaveFileName: '',
        list: [],
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0
      };
    },
    created() {
      this.getList();

    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询数据库备份记录列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        backupDbList(this.query).then(res => {
          if (res.status) {
            this.list = res.data
            this.total = res.total;
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      /** 失去焦点修改input中的值 */
      blurChange(type, event) {
        if (type == 'master') {
          this.masterFileName = event.target.value
        } else {
          this.slaveFileName = event.target.value;
        }
      },

      /** 执行一次数据库备份 */
      handleDbBackUp(type) {
        let fileName = type == 'master' ? this.masterFileName: this.slaveFileName;
        let database = type == 'master'? '主数据库':'从数据库';
        this.doDbBackUp(type, database, fileName);
      },
      doDbBackUp(type, database, fileName) {
        this.$confirm('即将进行【'+database+'】备份，备份名【'+fileName+'】，这将花费较长时间，是否确认继续?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return backupDb(
                  {
                    database: type,
                    fileName: fileName
                  }
          );
        }).then(() => {
          this.getList();
          this.$message({
            type: 'success',
            message: "操作成功"
          });
        })
      },
      exportExcel() {
        backupDbListExport(this.query).then(res => {
          if (!res.data) {
            return
          }
          let url = window.URL.createObjectURL(new Blob([res.data]))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '数据库备份日志.xlsx')

          document.body.appendChild(link)
          link.click()
        })
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
    }
  };
</script>

<template>
  <Layout>
    <BackupHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 225px;">
          <div class="card-body">
            <h4 class="card-title ">数据库备份设置</h4>
            <div class="backup-box">
              <div class="d-flex">
                <div class="backup-name">主数据库配置</div>
                <div class="col-sm-11">
                  <div class="flexList backup-val border-dash">
                    <div class="frame-wrap col-sm-4">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="value1" value="男">
                        <label class="custom-control-label" style="line-height:23px" for="value1">每天备份 </label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input " id="value2" value="女">
                        <label class="custom-control-label" style="line-height:23px" for="value2">每周备份一次</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input " id="value2" value="女">
                        <label class="custom-control-label" style="line-height:23px" for="value2">每月备份一次</label>
                      </div>
                    </div>
                    <button type="button" class="btn btn-info h30 w-sm">保存</button>
                  </div>
                  <div class="flexList backup-val border-dash">
                    <input type="" @blur="blurChange('master', $event)" value="" placeholder="填写备份文件名(如：master202202011830)不填写则自动命名"
                      class="form-control sys-table-input mr-2" />
                    <button type="button" class="btn btn-info h30 w-sm" @click="handleDbBackUp('master')">立即备份</button>
                  </div>

                </div>
              </div>
              <div class="d-flex">
                <div class="backup-name">从数据库配置</div>
                <div class="col-sm-11">
                  <div class="flexList backup-val border-dash">
                    <div class="frame-wrap col-sm-4">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="value1" value="男">
                        <label class="custom-control-label" style="line-height:23px" for="value1">每天备份 </label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input " id="value2" value="女">
                        <label class="custom-control-label" style="line-height:23px" for="value2">每周备份一次</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input " id="value2" value="女">
                        <label class="custom-control-label" style="line-height:23px" for="value2">每月备份一次</label>
                      </div>
                    </div>
                    <button type="button" class="btn btn-info h30 w-sm">保存</button>
                  </div>
                  <div class="flexList backup-val border-dash">
                    <input type="" @blur="blurChange('slave', $event)" value=""  placeholder="填写备份文件名(如：slave202202011830)不填写则自动命名"
                      class="form-control sys-table-input mr-2" />
                    <button type="button" class="btn btn-info h30 w-sm" @click="handleDbBackUp('slave')">立即备份</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class="pb-3  border-dash check-table-top">
              <div class="flexList">
                <el-select class=" mr-2 blueBorder" style="width: 40%" placeholder="选择日志库" v-model="query.database"
                           size="small">
                  <el-option value="" label="所有日志库"></el-option>
                  <el-option value="master" label="主数据库"></el-option>
                  <el-option value="slave" label="从数据库"></el-option>
                </el-select>
                <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick()"><i class="iconfont mr-2 icon-mb-search"></i>查询</b-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab" @click="exportExcel"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>

            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"> 序号</th>
                    <th style="width: 10%;">数据名</th>
                    <th style="width: 10%;">数据库类别</th>
                    <th>备份地址 </th>
                    <th style="width: 10%;">操作人 </th>
                    <th style="width: 12%;">备份时间 </th>
                    <th style="width: 8%;">来源 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in list" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.dbname}}</td>
                    <td>{{obj.dbtype}}</td>
                    <td>{{obj.bakupPath}}</td>
                    <td>{{obj.userName}}</td>
                    <td>{{obj.createTime}}</td>
                    <td>{{obj.modifyIp}}</td>
                  </tr>
                  <!--<tr>-->
                    <!--<td> 1 </td>-->
                    <!--<td>sYS_NAME </td>-->
                    <!--<td>主数据库</td>-->
                    <!--<td>e:kbakirssys2021121712928.bak </td>-->
                    <!--<td>system</td>-->
                    <!--<td>2021-03-18 11:12:29</td>-->
                    <!--<td> 127.0.0.1 </td>-->
                  <!--</tr>-->
                  <!--<tr>-->
                    <!--<td> 1 </td>-->
                    <!--<td>sYS_NAME </td>-->
                    <!--<td>主数据库</td>-->
                    <!--<td>e:kbakirssys2021121712928.bak </td>-->
                    <!--<td>system</td>-->
                    <!--<td>2021-03-18 11:12:29</td>-->
                    <!--<td> 127.0.0.1 </td>-->
                  <!--</tr>-->
                  <!--<tr>-->
                    <!--<td> 1 </td>-->
                    <!--<td>sYS_NAME </td>-->
                    <!--<td>主数据库</td>-->
                    <!--<td>e:kbakirssys2021121712928.bak </td>-->
                    <!--<td>system</td>-->
                    <!--<td>2021-03-18 11:12:29</td>-->
                    <!--<td> 127.0.0.1 </td>-->
                  <!--</tr>-->
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="currentPage" :page-size="perPage"
                             layout="total, sizes,jumper, prev, pager, next" :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
